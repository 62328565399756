import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

/**
 * Camera Service
 */
@Injectable()
export class CameraService {

	/**
	 * Photo data url
	 */
	private photoDataUrl: BehaviorSubject<string | ArrayBuffer | null> = new BehaviorSubject(null);

	/**
	 * Current photo data url
	 */
	public currentPhotoDataUrl: Observable<string | ArrayBuffer | null> = this.photoDataUrl.asObservable();

	/**
	 * Image file
	 */
	private imageFile: BehaviorSubject<File | Blob> = new BehaviorSubject(null);

	/**
	 * Current image file
	 */
	public currentImageFile: Observable<File | Blob> = this.imageFile.asObservable();

	constructor() { }

	/**
	 * Add/Change photo dataUrl
	 *
	 * @param imageFile, imageFile to dataUrl
	 */
	public changePhotoDataUrl(imageFile: File| Blob): void {
		const reader: FileReader = new FileReader();
		reader.readAsDataURL(imageFile);
		reader.onload = (): void => {
			this.photoDataUrl.next(reader.result);
		};
	}

	/**
	 * Add/Change photo imageFile
	 *
	 * @param imageFile, photo data url format
	 */
	public changeImageFile(imageFile: File | Blob): void {
		this.imageFile.next(imageFile);
	}
}
