import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IApiResponse} from "../../shared/interfaces/api-response.interface";
import {IProjects} from "./projects.interface";
import {environment} from "../../../environments/environment";
import {IExpense} from "./expense.interface";

/**
 * IExpense Service API
 */
@Injectable()
export class ExpenseService {

	constructor(private httpClient: HttpClient) {
	}

	/**
	 * Get billable
	 */
	public getBillable(): Promise<Array<IProjects>> {
		return this.httpClient.get(`${environment.API_BASE_URL}/customers/billable`)
			.toPromise()
			.then((results: IApiResponse<IProjects>) => results.data)
			.catch((error) => {
				console.log('ExpenseService.getBillable(): ', error);

				return Promise.reject();
			});
	}

	/**
	 * Get customers
	 */
	public getCustomers(): Promise<Array<IProjects>> {
		return this.httpClient.get(`${environment.API_BASE_URL}/customers/projects`)
			.toPromise()
			.then((results: IApiResponse<IProjects>) => results.data)
			.catch((error) => {
				console.log('ExpenseService.getCustomers(): ', error);

				return Promise.reject();
			});
	}

	/**
	 * Create
	 * @param expense, the expense object
	 */
	public createExpense(expense: IExpense): Promise<any> {
		return this.httpClient.post(`${environment.API_BASE_URL}/expenses`, this.toFormData(expense))
			.toPromise()
			.then((results: IApiResponse<any>) => results.data)
			.catch((error) => {
				console.log('ExpenseService.getCustomers(): ', error);

				return Promise.reject();
			});
	}

	/**
	 * Form group values convert to form data (to upload image)
	 * @param formValue, formControl value
	 */
	private toFormData<T>(formValue: T): FormData {
		const formData: FormData = new FormData();

		for (const key of Object.keys(formValue)) {
			const value = formValue[key];
			formData.append(key, value);
		}

		return formData;
	}
}
