import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
	{path: '', redirectTo: 'home', pathMatch: 'full'},
	{path: 'home', loadChildren: './home/home.module#HomePageModule'},
	{path: 'expense', loadChildren: './expense/expense.module#ExpensePageModule'},
];

/**
 * App routing module
 */
@NgModule({
	imports: [
			RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
